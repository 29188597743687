<template>
    <div>
        <div v-if="isLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <h6 class="text-body mt-3">Loading</h6>

        </div>
        <div v-if="!isLoading && errorLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-icon-exclamation-triangle class="h3" variant="danger"></b-icon-exclamation-triangle>
            <h6 class="text-danger mt-3">Ooops, there has been an error</h6>

        </div>
        <div v-if="!isLoading && !errorLoading">
            <h5 class="border-bottom pb-2 text-primary">{{ formData.id !== undefined ? "Edit" : "Create"}} FAQ</h5>
            <form autocomplete="off">
                <b-form-group
                        label-size="sm"
                        label="Question:">
                    <b-form-input size="sm" v-model="formData.question" placeholder="Question"></b-form-input>
                    <error-display v-model="errors" ident="name"></error-display>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Answer:">
                    <vue-editor v-model="formData.answer"></vue-editor>
                    <error-display v-model="errors" ident="answer"></error-display>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Relatedt categories:">
                    <div v-if="isLoadingCategories" class="d-flex align-items-center border p-2 rounded">
                        <b-spinner small variant="primary" class="mr-2"></b-spinner>
                        Loading categories
                    </div>
                    <div v-else >
                        <v-select
                                ref="userSelect"
                                label="name"
                                v-model="formData.support_category_ids"
                                :multiple="true"
                                :reduce="option => option.value"
                                :options="categories"
                                :filterable="true"
                                placeholder="Related categories">
                        </v-select>
                    </div>
                    <error-display v-model="errors" ident="support_category_ids"></error-display>
                </b-form-group>

                <div class="text-right">
                    <b-button @click="saveModel" variant="success">Save</b-button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
    import {getResource, saveResource} from "../../modules/api/methods";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import _ from 'lodash'
    import { VueEditor } from "vue2-editor";
    import vSelect from "vue-select"

    import {
        faqDetail,
        faqSave,
        nationalityGroupDetail,
        nationalityGroupSave, supportCategories,
    } from "../../modules/api/endpoints";
    import {containsErrors} from "../../modules/helpers/helpers";

    export default {
        name: "FaqEdit",
        components: {"v-select": vSelect, ErrorDisplay,VueEditor},
        props: {
            id: null,
        },
        data: function () {
            return {
                isLoadingCategories : false,
                errorLoading : false,
                isLoading: false,
                formData: {},
                errors : {},
                categories : [],
            }
        },
        created() {
            if (this.id != null) {
                this.loadModel();
            }
            this.loadCategories();
        },
        methods: {
            loadCategories() {
                this.isLoadingCategories = true;
                getResource(supportCategories).then((resp) => {
                    this.categories = resp.data.success
                }).catch((ex) => {
                    this.$root.$children[0].handleApiError(ex, this.loadCategories);
                }).finally(() => {
                    this.isLoadingCategories = false;
                });
            },
            loadModel() {
                this.isLoading = true;
                getResource(faqDetail(this.id)).then((resp) => {
                    var data = resp.data.success.data;
                    //TRANSFORM DATA
                    var catIds = [];
                    _.each(data.support_categories,function(category) {
                        catIds.push(category.id);
                    });
                    data.support_category_ids = catIds;
                    this.formData = data;
                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            saveModel() {
                this.isLoading = true;
                this.errors = {};
                saveResource(faqSave,this.formData).then((resp) => {
                    this.isLoading = false;
                    this.$router.push({'name' : 'faqs', params: {data_updated : true}}).catch(error => {});
                }).catch((err) => {
                    if(containsErrors(err)) {
                        this.errors = err.data.errors;
                    }
                    this.isLoading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
